/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// UI lib components

// UI local components
import Seo from '../shared/Seo';
import PageLayout from '../shared/PageLayout';

// style
import '../page-styles/index.scss';

// Helpers
import { isBrowser } from '../shared/Helpers/isBrowser';

// UI local components
const Hero = React.lazy(() => import('../page-components/Index/Hero'));
const ExploreSection = React.lazy(() => {
  return import('../page-components/Index/ExploreSection');
});
const AboutUs = React.lazy(() => {
  return import('../page-components/Index/AbouUs');
});
const ContactForm = React.lazy(() => {
  return import('../page-components/Index/ContactForm');
});
const Statistic = React.lazy(() => {
  return import('../page-components/Index/Statistic');
});
const Centre = React.lazy(() => import('../page-components/Index/Centre'));
const OurSpecialities = React.lazy(() => {
  return import('../page-components/Index/OurSpecialities');
});

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function IndexPage() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      {isBrowser() ? (
        <React.Suspense fallback={null}>
          <Hero />
          <ExploreSection />
          <AboutUs />
          <Statistic />
          <OurSpecialities />
          <Centre />
          <ContactForm />
        </React.Suspense>
      ) : null}
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
